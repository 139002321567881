import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link, withRouter } from "react-router-dom";
import FormComponent from "../../components/users/FormComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WebsiteFormComponent from "../../components/websites/WebsiteFormComponent";
import PageAddFormComponent from "../../components/pages/PageAddFormComponent";
import PageEditComponent from "../../components/pages/PageEditComponent";
import PageSeoComponent from "../../components/pages/PageSeoComponent";
import ContentBlockFormComponent from "../../components/content-blocks/ContentBlockFormComponent";
import MediaAlbumComponent from "../../components/media/album/MediaAlbumComponent";
import EyeCatcherComponent from "../../components/pages/eyecatcher/EyeCatcherComponent";
import ModuleFormComponent from "../../components/modules/ModuleFormComponent";
import CategoryAddFormComponent from "../../components/categories/CategoryAddFormComponent";
import CategoryEditComponent from "../../components/categories/CategoryEditComponent";
import CategoryContentComponent from "../../components/categories/CategoryContentComponent";
import TestimonialAddFormComponent from "../../components/testimonials/TestimonialAddFormComponent";
import TestimonialEditFormComponent from "../../components/testimonials/TestimonialEditFormComponent";
import FaqAddFormComponent from "../../components/faqs/FaqAddFormComponent";
import FaqEditFormComponent from "../../components/faqs/FaqEditFormComponent";
import FormFormComponent from "../../components/forms/FormFormComponent";
import FormMailFormComponent from "../../components/forms/FormMailFormComponent";
import MapFormComponent from "../../components/maps/MapFormComponent";
import Module from "../../models/module/Module";
import AttendeeFormComponent from "../../components/attendee/AttendeeFormComponent";
import BlogFormComponent from "../../components/blog/BlogFormComponent";
import BlogContentComponent from "../../components/blog/BlogContentComponent";
import EventFormComponent from "../../components/event/EventFormComponent";
import EventContentComponent from "../../components/event/EventContentComponent";
import NewsFormComponent from "../../components/news/NewsFormComponent";
import ProjectFormComponent from "../../components/project/ProjectFormComponent";
import TeamFormComponent from "../../components/team/TeamFormComponent";
import WeblinkFormComponent from "../../components/weblink/WeblinkFormComponent";
import ClientFormComponent from "../../components/client/ClientFormComponent";
import EventRegistrationFormComponent from "../../components/event/EventRegistrationFormComponent";
import ProductFormComponent from "../../components/product/ProductFormComponent";
import ProductImportComponent from "../../components/product/ProductImportComponent";

class FormFragment extends React.Component {

    constructor(props) {
        super(props);

        this._action = 'add';
        if (this.props.match.path.indexOf('/edit') > -1 || this.props.match.path.indexOf('/config') > -1) {
            this._action = 'edit';
        } else if (this.props.match.path.indexOf('/import') > -1) {
            this._action = 'import';
        }

        this.available_forms = {
            "user": FormComponent,
            "websites": WebsiteFormComponent,

            "pages": PageAddFormComponent,
            "pages-edit": PageEditComponent,
            "pages-seo": PageSeoComponent,
            "pages-photo-album": MediaAlbumComponent,
            "pages-video-album": MediaAlbumComponent,
            "pages-eye-catcher": EyeCatcherComponent,
            "pages-background-images": MediaAlbumComponent,
            "pages-background-videos": MediaAlbumComponent,

            "forms": FormFormComponent,
            "forms-mail-settings": FormMailFormComponent,

            "content-blocks": ContentBlockFormComponent,

            "modules": ModuleFormComponent,

            "testimonials": TestimonialAddFormComponent,
            "testimonials-edit": TestimonialEditFormComponent,

            "faqs": FaqAddFormComponent,
            "faqs-edit": FaqEditFormComponent,

            "categories": CategoryAddFormComponent,
            "categories-edit": CategoryEditComponent,
            "categories-content": CategoryContentComponent,
            "categories-photo-album": MediaAlbumComponent,

            "attendees": AttendeeFormComponent,
            "clients": ClientFormComponent,

            "maps": MapFormComponent,

            "news": NewsFormComponent,
            "news-photo-album": MediaAlbumComponent,
            "news-video-album": MediaAlbumComponent,

            "projects": ProjectFormComponent,
            "projects-photo-album": MediaAlbumComponent,
            "projects-video-album": MediaAlbumComponent,

            "teams": TeamFormComponent,

            "weblinks": WeblinkFormComponent,

            "blog": BlogFormComponent,
            "blog-content": BlogContentComponent,
            "blog-photo-album": MediaAlbumComponent,
            "blog-video-album": MediaAlbumComponent,

            "events": EventFormComponent,
            "event-registrations": EventRegistrationFormComponent,
            "event-content": EventContentComponent,
            "event-photo-album": MediaAlbumComponent,
            "event-video-album": MediaAlbumComponent,

            "products": ProductFormComponent,
            "product-import": ProductImportComponent,
            "product-photo-album": MediaAlbumComponent,
            "product-video-album": MediaAlbumComponent,
        };

        this.state = {
            title_id: props.title_id
        };
    }

    updateTitle = (title_id) => this.setState({ title_id: title_id });

    goBack = (module = false) => module ?
        this.props.history.push('/module/' + this.props.slug + '/' + Module.getId() + '/overview') :
        this.props.history.push("/" + this.props.slug);

    goBackElement = () => {
        let slug = '/' + this.props.slug;
        if (this.props.location.pathname.indexOf('/module/') > -1) {
            slug = '/module/' + this.props.slug + '/' + Module.getId() + '/overview';
        }

        return <FormattedMessage id="CMS.User.overview.back">
            {value => (
                <Link to="#" onClick={() => { slug ? this.props.history.push(slug) : this.props.history.goBack(); }}>
                    <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                    <span>{value}</span>
                </Link>
            )}
        </FormattedMessage>
    }

    titleElement = () => {
        return this.state.title_id !== undefined ?
            <FormattedMessage id={this.state.title_id}>
                {(value) => <h1>{value}</h1>}
            </FormattedMessage>
            :
            <FormattedMessage id={this.props.single_title_id}>
                {(value) =>
                    <FormattedMessage id={'CMS.Form.overview.' + this._action + '.text'} values={{ text: value }}>
                        {(value) => <h1>{value}</h1>}
                    </FormattedMessage>
                }
            </FormattedMessage>
    }

    render() {
        return (
            <Col xl={this.props.full ? 12 : 10} lg={this.props.full ? 12 : 12}>
                {this.props.text_id ?
                    <>
                        <Row>
                            <Col>
                                <div className={"title form-title"}>
                                    {this.titleElement()}
                                    {this.goBackElement()}
                                </div>
                                <FormattedMessage id={this.props.text_id}>
                                    {(value) => <p className={"overview-text"}>{value}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                        {this.form()}
                    </>
                    :
                    <>
                        <div className={"title form-title"}>
                            {this.titleElement()}
                            {this.goBackElement()}
                        </div>
                        {this.form()}
                    </>
                }
            </Col>
        )
    }

    form = () => {
        const Form = this.available_forms[this.props.component_name];
        if (Form !== undefined) {
            return <Form {...this.props} action={this._action} id={this.props.match.params.id} parent={this} goBack={this.goBack} />
        }
    }
}

export default withRouter(FormFragment);