import moment from "moment";
import { PriceHelper } from "../../helpers";

export default class Attendee {
    id;
    website;
    client = [];
    active;
    status;

    first_name;
    last_name;
    email;
    birth_date;
    gender;
    phone;
    national_insurance_number;
    medical_info;

    webshop_discount_code;

    membership_price;
    membership_paid;
    membership_expires;

    additional_dynamic_field_1;
    additional_dynamic_field_2;
    additional_dynamic_field_3;

    static formJson = (json) => {
        let item = new Attendee();

        item.website = json.website;
        item.module = json.module;
        item.client = json.client;


        item.id = json.id;
        item.active = json.active;
        item.status = json.status;

        item.first_name = json.first_name;
        item.last_name = json.last_name;
        item.email = json.email || '';

        item.gender = json.gender || '';
        item.birth_date = json.birth_date || '';
        item.national_insurance_number = json.national_insurance_number || '';
        item.phone = json.phone || '';
        item.medical_info = json.medical_info || '';

        item.webshop_discount_code = json.webshop_discount_code || '';

        item.additional_dynamic_field_1 = json.additional_dynamic_field_1;
        item.additional_dynamic_field_2 = json.additional_dynamic_field_2;
        item.additional_dynamic_field_3 = json.additional_dynamic_field_3;

        item.membership_price = PriceHelper.price(json.membership_price ? json.membership_price : 0);
        item.membership_paid = json.membership_paid ? moment(json.membership_paid) : null;
        item.membership_expires = json.membership_expires ? moment(json.membership_expires) : null;

        return item;
    }

    fullname = () => this.first_name + ' ' + this.last_name;
}
