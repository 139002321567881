import React from "react";
import { DeleteModalComponent, StandardTableComponent, TableEditActionComponent, TableDeleteActionComponent } from "../basics/table";
import { Button } from "react-bootstrap";
import { CheckPermissions, GeneralSettingsHelper } from "../../helpers";
import UserRolesEnum from "../../enums/UserRolesEnum";
import { ClientRepository } from "../../repository";
import FormToastComponent from "../basics/FormToastComponent";
import { FormattedMessage } from "react-intl";
import FormattedMessageString from "../basics/FormattedMessageString";
import Module from "../../models/module/Module";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoaderComponent from "../basics/layout/LoaderComponent";
import TableDropdownActionComponent from "../basics/table/TableDropdownActionComponent";

class ClientOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            rows: this.props.rows,
            settings: Module.getSettings(),
            isLoading: false
        };

    }

    columns() {
        let columns = [
            {
                dataField: 'id',
                hidden: true
            },
            {
                dataField: 'internal_id',
                text: <FormattedMessageString id="CMS.Client.overview.table.internal_id" />,
                sort: true,
                isDummyField: true,
                formatter: (cellContent, row) => row.internal_id
            },
            {
                dataField: 'first_name',
                text: <FormattedMessageString id="CMS.Client.overview.table.first_name" />,
                sort: true,
                isDummyField: true,
                formatter: (cellContent, row) => row.first_name
            },
            {
                dataField: 'last_name',
                text: <FormattedMessageString id="CMS.Client.overview.table.last_name" />,
                sort: true,
                isDummyField: true,
                formatter: (cellContent, row) => row.last_name
            },
            {
                dataField: 'registered',
                text: <FormattedMessageString id="CMS.Client.overview.table.registered" />,
                sort: true,
                isDummyField: true,
                formatter: (cellContent, row) => row.created_at.format('DD/MM/YYYY')
            }
        ];

        if (this.state.settings.use_membership) {
            columns = [
                ...columns, ...[
                    {
                        dataField: 'membership_price',
                        text: <FormattedMessageString id="CMS.Client.overview.table.membership_price" />,
                        sort: true,
                        isDummyField: true,
                        formatter: (cellContent, row) => '€ ' + row.membership_price
                    }
                ]
            ]
        }

        columns = [...columns, ...[
            {
                dataField: 'status',
                text: <FormattedMessageString id="CMS.Client.overview.table.status" />,
                sort: true,
                isDummyField: true,
                formatter: (cellContent, row) => {
                    switch (row.status) {
                        case 'active':
                            return (
                                <FormattedMessage id={'CMS.Client.overview.table.status.' + row.status}>
                                    {value => <div className="badge badge-success">
                                        <FontAwesomeIcon icon={['fa', 'fa-check']} />
                                        {value}
                                    </div>}
                                </FormattedMessage>

                            )
                        case 'membership-paid':
                            return (
                                <>
                                    <FormattedMessage id={'CMS.Client.overview.table.status.' + row.status}>
                                        {value => <div className="badge badge-success">
                                            <FontAwesomeIcon icon={['fa', 'fa-check']} />
                                            {value}
                                        </div>}
                                    </FormattedMessage>

                                    <FormattedMessage id="CMS.Client.overview.table.status.membership_paid_at" values={{ date: row.membership_paid.format('DD/MM/YYYY') }}>
                                        {value => <p>{value}</p>}
                                    </FormattedMessage>
                                </>
                            );
                        case 'membership-awaiting-payment':
                            return (
                                <>
                                    <FormattedMessage id={'CMS.Client.overview.table.status.' + row.status}>
                                        {value => <div className="badge badge-warning">
                                            <FontAwesomeIcon icon={['fa', 'fa-stopwatch']} />
                                            {value}
                                        </div>}
                                    </FormattedMessage>
                                    <br />
                                    <FormattedMessage id="CMS.Client.overview.table.status.membership_set_paid">
                                        {value => <Button variant="link" type="button" size="sm"
                                            onClick={() => this.setMembershipPaid(row)}
                                        >
                                            {value}
                                        </Button>}
                                    </FormattedMessage>
                                </>
                            )
                        case 'inactive':
                        default:
                            return (
                                <>
                                    <FormattedMessage id={'CMS.Client.overview.table.status.' + row.status}>
                                        {value => <div className="badge badge-danger">
                                            <FontAwesomeIcon icon={['fa', 'fa-times']} />
                                            {value}
                                        </div>}
                                    </FormattedMessage>

                                    <FormattedMessage id="CMS.Client.overview.table.status.membership_email_not_confirmed">
                                        {value => <p>{value}</p>}
                                    </FormattedMessage>
                                </>
                            )
                    }
                }
            },
            {
                dataField: 'last_login',
                text: <FormattedMessageString id="CMS.Client.overview.table.last_login" />,
                sort: true,
                isDummyField: true,
                formatter: (cellContent, row) => row.last_login ? row.last_login.format('DD/MM/YYYY HH:mm') : '-'
            },
            {
                dataField: 'actions',
                isDummyField: true,
                text: <FormattedMessage id="CMS.Table.actions" />,
                hidden: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR),
                sort: false,
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '10%' };
                },
                formatter: (cellContent, row) => {
                    let mailActions = [];

                    if (!row.email_verified_at) {
                        mailActions.push({
                            key: 'verify',
                            icon: 'shield-check',
                            label: 'CMS.Client.overview.table.notification.verify',
                            callback: () => this.notify(row.id, 'verify')
                        });
                    } else {
                        if (row.status === 'membership-awaiting-payment') {
                            mailActions.push({
                                key: 'payment_instructions',
                                icon: 'receipt',
                                label: 'CMS.Client.overview.table.notification.payment_instructions',
                                callback: () => this.notify(row.id, row.membership_expires ? 'payment-instructions-renewal' : 'payment-instructions')
                            });
                        }

                        if (row.status !== 'inactive') {
                            mailActions.push({
                                key: 'password-reset',
                                icon: 'key',
                                label: 'CMS.Client.overview.table.notification.password_reset',
                                callback: () => this.notify(row.id, 'password-reset')
                            });
                        }
                    }

                    return <div className="actions">
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && !GeneralSettingsHelper.is() && (
                            <>
                                <TableDropdownActionComponent id={row.id} icon="envelope" items={mailActions} />
                                {row.active ?
                                    <Link onClick={() => this.toggleActive(row)} className="active" to="#">
                                        <FontAwesomeIcon icon={['fas', 'check']} />
                                    </Link>
                                    :
                                    <Link onClick={() => this.toggleActive(row)} className="inactive" to="#">
                                        <FontAwesomeIcon icon={['fas', 'times']} />
                                    </Link>
                                }
                                <TableEditActionComponent id={row.id} custom={''} />
                                <TableDeleteActionComponent row={row} parent={this} />
                            </>
                        )}
                    </div >
                }
            }]];

        return columns;
    }

    updateData = () => ClientRepository.all().then(response => this.props.parent.setState({ rows: response }));
    notify = (id, notification) => ClientRepository.notify({ id: id, notification: notification }).then(() => FormToastComponent.successTrans('Client', 'CMS.Client.overview.table.notification.sent'))

    render() {
        if (this.state.isLoading) return <LoaderComponent />;
        if (this.state.reload) return <LoaderComponent />;

        return (
            <div className="custom-data-table client">
                <StandardTableComponent keyField="id" data={this.state.rows} columns={this.columns()} search={true} subRows={false}
                    title={this.props.title_id} type={this.props.type}
                    sort={{ dataField: 'published_at', order: 'desc' }}
                />
                <DeleteModalComponent parent={this} />
            </div>
        );
    }

    toggleActive = (_row) => ClientRepository.toggle(_row.id)
        .then(response => {
            let newRows = [...this.state.rows];
            newRows = newRows.map((row) => {
                if (row.id === _row.id) {
                    row.active = response;
                }
                return row;
            });
            this.setState(curr => ({ ...curr, rows: newRows, reload: true }), () => {
                this.setState({ reload: false })
            });
        })
        .catch(error => FormToastComponent.errorTrans('CMS.Client.overview.toggle.failed'));

    setMembershipPaid = (_row) => ClientRepository.quickUpdate({ id: _row.id, action: 'status', value: 'membership-paid' })
        .then(response => this.setState(curr => ({ ...curr, rows: [...this.state.rows].map(row => row.id === _row.id ? response : row), reload: true }), () => {
            this.setState({ reload: false })
        }))
        .catch(error => FormToastComponent.errorTrans('CMS.Client.overview.toggle.failed'));
}

export default ClientOverviewTableComponent;
