import React from "react";
import { DeleteModalComponent, StandardTableComponent, TableEditActionComponent, TableDeleteActionComponent, TableToggleActiveAction } from "../basics/table";
import Website from "../../models/website/Website";
import { ListGroup, Tab } from "react-bootstrap";
import { Application, CountryHelper, CheckPermissions } from "../../helpers";
import UserRolesEnum from "../../enums/UserRolesEnum";
import { ModuleRepository, ProductRepository, WebsiteRepository } from "../../repository";
import FormToastComponent from "../basics/FormToastComponent";
import { FormattedMessage } from "react-intl";
import FormattedMessageString from "../basics/FormattedMessageString";
import Module from "../../models/module/Module";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToolTipLink from "../basics/ToolTipLink";
import LoaderComponent from "../basics/layout/LoaderComponent";
import { ButtonStatistic } from "../basics/button";
import ProductFilterWidget from "./widget/ProductFilterWidget";
import ProductHelper from "../../helpers/ProductHelper";

class ProductOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: this.props.rows,
            settings: Module.getSettings(),
            website: null,
            isLoading: true,
            language: Application.tableLanguage()
        };
    }

    componentDidMount() {
        let promises = [
            WebsiteRepository.get(Website.get()),
            ModuleRepository.getSettings()
        ];

        Promise.all(promises)
            .then(response => {
                let categories = [];

                let settings = response[1].data?.settings;
                if (settings.use_categories) {
                    settings.references.filter(reference => reference.children.length <= 0).forEach(reference => {
                        categories.push({ id: reference.id, label: reference.depthLabel.join(' > ') });
                    })
                }

                this.setState({
                    website: response[0],
                    categories: categories,
                    extraButtons: [
                        { path: '/module/products/' + Module.get().id + '/import', 'text': 'CMS.Product.overview.link.import', role: 'products' }
                    ],
                    isLoading: false
                });
            })
    }

    columns = (index) => {
        return [
            {
                dataField: 'internal_id',
                text: <FormattedMessageString id="CMS.Product.overview.table.id" />,
                headerClasses: 'text-center',
                style: { width: '50px' },
                classes: 'text-center',
                sort: true,
            },
            this.state.settings.show_cover_image ? {
                dataField: 'image',
                text: <FormattedMessageString id="CMS.Product.overview.table.image" />,
                sort: false,
                headerStyle: { width: '70px' },
                formatter: (cellContent, row) => {
                    let src = 'https://placehold.co/70';

                    if (row.media?.product_image) {
                        let image = Object.values(row.media.product_image)[0];
                        if (image.sized_images?.thumbnail_overview) {
                            src = this.state.website.domain + '/' + this.state.website.sftp_media_path + image.sized_images.thumbnail_overview;
                        } else {
                            src = this.state.website.domain + '/' + this.state.website.sftp_media_path + image.path;
                        }
                    }

                    return <img src={src} width="70" height="70" alt={row.contents[index].title} />
                }
            } : {},
            this.state.settings.use_categories ? {
                dataField: 'category',
                text: <FormattedMessageString id="CMS.Product.overview.table.category" />,
                headerStyle: { width: '10%' },
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    let contentsA = rowA.contents[index];
                    let contentsB = rowB.contents[index];

                    if (order === 'asc')
                        return contentsA.category.title.toLowerCase() < contentsB.category.title.toLowerCase() ? -1 : 1;
                    else if (order === 'desc')
                        return contentsB.category.title.toLowerCase() > contentsA.category.title.toLowerCase() ? 1 : -1;
                },
                formatter: (cellContent, row) => row.contents[index].category.title
            } : {},
            this.state.settings.show_article_number ? {
                dataField: 'article_number',
                text: <FormattedMessageString id="CMS.Product.overview.table.article_number" />,
                headerStyle: { width: '10%' },
                sort: false,
                formatter: (cellContent, row) => row.article_number
            } : {},
            {
                dataField: 'title',
                text: <FormattedMessageString id="CMS.Product.overview.table.title" />,
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    let contentsA = rowA.contents[index];
                    let contentsB = rowB.contents[index];

                    if (order === 'asc')
                        return contentsA.title.toLowerCase() < contentsB.title.toLowerCase() ? -1 : 1;
                    else if (order === 'desc')
                        return contentsB.title.toLowerCase() > contentsA.title.toLowerCase() ? 1 : -1;
                },
                isDummyField: true,
                formatter: (cellContent, row) => row.contents[index].title
            },
            this.state.settings.show_stock ? {
                dataField: 'category',
                text: <FormattedMessageString id="CMS.Product.overview.table.stock" />,
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    if (order === 'asc')
                        return rowA.stock < rowB.stock ? -1 : 1;
                    else if (order === 'desc')
                        return rowB.stock > rowA.stock ? 1 : -1;
                },
                formatter: (cellContent, row) => <FormattedMessageString id="CMS.Product.overview.table.stock_value" values={{ number: row.stock }} />
            } : {},
            {
                dataField: 'actions',
                isDummyField: true,
                text: <FormattedMessage id="CMS.Table.actions" />,
                hidden: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR),
                sort: false,
                editable: false,
                headerStyle: (colum, colIndex) => { return { width: '10%' }; },
                formatter: (cellContent, row) => {
                    let content = row.contents[index];
                    return <div className="actions">
                        <ButtonStatistic value={content.views} />

                        {this.state.settings.use_spotlight && (
                            <ToolTipLink onClick={() => this.toggle(row, 'spotlight')} to="#" title={row.spotlight ? 'CMS.Product.overview.tooltip.spotlight_off' : 'CMS.Product.overview.tooltip.spotlight'}>
                                <FontAwesomeIcon icon={[(row.spotlight ? 'fas' : 'far'), 'star']} />
                            </ToolTipLink>
                        )}
                        {this.state.settings.show_photoalbum && (
                            <ToolTipLink className="default" to={"photoalbum/" + row.id} title="CMS.Product.overview.tooltip.show_photoalbum">
                                <FontAwesomeIcon icon={['far', 'images']} size="lg" />
                            </ToolTipLink>
                        )}
                        {this.state.settings.show_videoalbum && (
                            <ToolTipLink className="default" to={"videoalbum/" + row.id + (content !== undefined ? '/' + content.language : '/')} title="CMS.Product.overview.tooltip.show_videoalbum">
                                <FontAwesomeIcon icon={['far', 'video-plus']} size="lg" />
                            </ToolTipLink>
                        )}

                        <TableToggleActiveAction item={row} toggle={this.toggle} />
                        <TableEditActionComponent id={row.id} custom={content !== undefined ? '/' + content.language : '/'} />
                        <TableDeleteActionComponent row={row} parent={this} />
                    </div >
                }
            }
        ];
    }

    updateData = () => ProductRepository.all().then(response => this.props.parent.setState({ rows: response }));

    render() {
        if (this.state.isLoading) return <LoaderComponent />;
        if (this.state.reload) return <LoaderComponent />;

        return (
            <div className="tab-tables">
                <Tab.Container id={"list-group-language-products"}
                    defaultActiveKey={"#" + (this.state.website.languages[this.state.language] !== undefined ? this.state.website.languages[this.state.language].language : this.state.website.languages[0].language)}>
                    <ListGroup>
                        {this.state.website.languages.map((language, index) =>
                            <ListGroup.Item action href={"#" + language.language} key={index}
                                onClick={() => this.setState({ language: index }, () => Application.setTableLanguage(index))}>
                                {CountryHelper.getLanguages().find(({ value }) => value + "" === language.language + "").label}
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                    <Tab.Content>
                        {this.state.website.languages.map((language, index) =>
                            <Tab.Pane eventKey={"#" + language.language} key={index}>
                                <ProductFilterWidget parent={this} />

                                <div className="custom-data-table product">
                                    <StandardTableComponent keyField="id" data={this.state.rows} columns={this.columns(index)} search={true}
                                        title={this.props.title_id} type={this.props.type}
                                        subRows={false}
                                        sort={{ dataField: 'title', order: 'asc' }}
                                    />
                                    <DeleteModalComponent parent={this} />
                                </div>
                            </Tab.Pane>
                        )}
                    </Tab.Content>
                </Tab.Container>
            </div>
        );
    }

    toggle = (_row, field) => ProductRepository.toggle(_row.id, field)
        .then(response => {
            let newRows = [...this.state.rows];
            newRows = newRows.map(row => {
                if (row.id === _row.id) {
                    row[field] = response;
                    return { ...row, product: row }
                }
                return row;
            });

            this.setState(curr => ({ ...curr, rows: newRows, reload: true }), () => {
                this.setState({ reload: false })
            });
        })
        .catch(() => FormToastComponent.errorTrans('CMS.Product.overview.toggle.failed'));

    filter = (filters) => {
        let rows = this.props.rows;

        if (filters.category) {
            rows = rows.filter(row => row.category === filters.category);
        }

        if (filters.image !== null) {
            if (filters.image) {
                rows = rows.filter(row => row.image);
            } else {
                rows = rows.filter(row => !row.image);
            }
        }

        if (filters.status !== null) {
            if (filters.status) {
                rows = rows.filter(row => row.active);
            } else {
                rows = rows.filter(row => !row.active);
            }
        }

        if (filters.spotlight !== null) {
            if (filters.spotlight) {
                rows = rows.filter(row => row.spotlight);
            } else {
                rows = rows.filter(row => !row.spotlight);
            }
        }

        if (filters.stock !== null) {
            if (filters.stock) {
                rows = rows.filter(row => row.stock > 0);
            } else {
                rows = rows.filter(row => row.stock <= 0);
            }
        }

        this.setState({ rows: rows });
    }

    export = () => ProductHelper.export(this.state.rows, { website: this.state.website, settings: this.state.settings, categories: this.state.categories });
}

export default ProductOverviewTableComponent;